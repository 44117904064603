export const prefix = 'hibrid.vod';

export default class Storage {
  /**
   * @param key {string}
   * @param value {any}
   */
  static set(key, value) {
    localStorage.setItem(Storage._makeKey(key), JSON.stringify(value))
  }

  /**
   * @param key {string}
   * @param defaultValue {null|string|[]}
   * @param multiValue {boolean}
   * @return {any}
   */
  static get(key, defaultValue = null, multiValue = false) {
    const value = localStorage.getItem(Storage._makeKey(key));
    if (!value) {
      return defaultValue
    }
    return multiValue ? JSON.parse(value) : value
  }

  /**
   * @param key {string}
   * @return {boolean}
   */
  static has(key) {
    return localStorage.getItem(Storage._makeKey(key)) !== false
  }

  /**
   * @param key {string}
   * @param value
   */
  static push(key, value) {
    const items = Storage.get(key, [], true);
    items.push(value);
    return Storage.set(key, items)
  }

  /**
   * @param key {string}
   * @return {string}
   * @private
   */
  static _makeKey(key) {
    return prefix + '.' + key
  }
}
