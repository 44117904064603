import Http from '../core/Http'
import Logger from '../core/Logger'
import GeoBlockedException from "../core/Exceptions/GeoBlockedException";

/** @class Entry*/
export default class Entry {

  /**
   * @param entry_id {string}
   * @param type {string}
   * @param options {object}
   */
  constructor(entry_id, type, options) {
    this.entry_id = entry_id;
    this.type = type;
    this.options = options;
  }

  /**
   * @param data {Object}
   */
  setData(data) {
    this.data = data
    return this
  }

  /**
   * @return {Object}
   */
  getData() {
    return this.data
  }

  /**
   * Fetch video from api
   * @return {AxiosPromise<any>}
   */
  fetch() {
    const {type, entry_id, options} = this
    Logger.info(`Fetching an entry [${type}] by id [${entry_id}]`)
    return Http.entry(type, entry_id, options)
      .then(({data}) => {
        if (data.statusCode && data.statusCode === 203) {
          throw new GeoBlockedException()
        }
        this.setData(data) && Logger.info('Entry request succeeded')
      })
      .catch((e) => {
        if (e instanceof GeoBlockedException) {
          throw e
        } else {
          this.__handleError(e)
        }
      })
  }

  __handleError(error) {
    throw new Error('An error occur while fetching video')
  }
}
