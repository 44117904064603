/** @class Instance */
import {DefaultSubscriber} from './Subscribers/DefaultSubscriber';
import Logger from '../Logger';

export default class Instance {
  /** @type {*[]}*/
  static events = [];

  /**
   * @param event {string}
   * @param subscribes {Array|Function}
   */
  static subscribe(event, subscribes) {
    const events = Instance.events[event];
    if (events) {
      events.push()
    }
  }

  /**
   * @param event {string}
   * @param subscriber {Class|Function}
   */
  static on(event, subscriber) {
    if (Instance._hasEvents(event)) {
      return Instance._pushEventSubscriber(event, subscriber)
    }
    Instance._newEventSubscriber(event, subscriber)
  }

  /**
   * @param event {string}
   * @param payload {Object}
   */
  static emit(event, payload) {
    Logger.info(`Executed event [${event}] with payload ${JSON.stringify(payload)}`);
    if (!Instance._hasEvents(event)) {
      Logger.info(`Event [${event}] has no subscribers`);
      return;
    }

    Instance
      ._getSubscribers(event)
      .forEach((subscriber) => {
        Logger.info(`Executing subscriber callback for event [${event}]`) || subscriber.handle(payload)
      })
  }

  /**
   * Check if given event already has subscribers
   * @param event
   * @private
   */
  static _hasEvents(event) {
    return Instance._eventIndex(event) > -1
  }

  /**
   * @param event {string}
   * @return {[*]|*[]}
   * @private
   */
  static _getSubscribers(event) {
    const index = Instance._eventIndex(event);
    return index > -1 ? Instance.events[index].subscribers : []
  }

  /**
   * Get event index from stack
   * @param event {string}
   * @return {number}
   * @private
   */
  static _eventIndex(event) {
    return Instance.events.findIndex(({name}) => name === event)
  }

  /**
   * Register subscriber for event
   * @param event {string}
   * @param subscriber {Class|Function}
   * @private
   */
  static _pushEventSubscriber(event, subscriber) {
    const index = Instance._eventIndex(event);
    const subscribers = Instance._getSubscribers(event);

    Instance.events[index] = {
      name: event,
      subscribers: subscribers.push(Instance._makeSubscriber(event, subscriber))
    }
  }

  static _newEventSubscriber(event, subscriber) {
    Instance.events.push({
      name: event,
      subscribers: [Instance._makeSubscriber(event, subscriber)]
    })
  }

  /**
   * @param event {string}
   * @param subscriber {Class|Function}
   * @return {*}
   * @private
   */
  static _makeSubscriber(event, subscriber) {
    return typeof subscriber === 'function' ? new DefaultSubscriber(event, subscriber) : subscriber
  }
}
