import Storage from '../../Storage';
import Http from '../../Http';

export class OnPlayerPlay {
  /**
   * @param id {string}
   */
  handle({id}) {
    if (this._wasPlayed(id)) {
      return;
    }
    Http.markAsPlayed(id)
      .then(() => Storage.push('played-videos', id))
  }

  /**
   * @param id {string}
   * @return {boolean}
   * @private
   */
  _wasPlayed(id) {
    const items = Storage.get('played-videos', [], true);
    return items.includes(id);
  }
}
