/** @class DefaultSubscriber*/
import Logger from '../../Logger';

export class DefaultSubscriber {

  /** @type {string}*/
  event;

  /** @type {Function}*/
  callable;

  /**
   * @param event {string}
   * @param callable {Function}
   */
  constructor(event, callable) {
    this.event = event;
    this.callable = callable;
  }

  /** @param payload {Object}*/
  handle(payload) {
    Logger.info(`Executing listener on [${this.event}]`);
    this.callable(payload)
  }
}
