import Logger from './Logger';
import Config from './Config';
import {randomUUID} from '../helpers';

export default class ElementGenerator {
  /**
   * ElementGenerator constructor
   */
  constructor() {
    this.element = Config.get('el');
    this._validateParentElement();
    this._randomElement()
  }

  /**
   * A method that will generate unique element to embed into page
   * Depends on which player user wants to embed, we will generate different tag
   *
   * For example: For video.js we need to generate `<video>` tag with the specified class names
   * while jwplayer can be embeded into `<div>` or `<span>` tags, but not into `<video>` tag
   *
   * @param player {string}
   */
  generate(player) {
    return this._createTag(player);
  }

  /**
   * This method is verified if user is not cheating
   * and parent element exist in current DOM node
   *
   * In case if element is not exist, we simple must throw an exception
   * and don't send any xhr request to the server
   *
   * @private
   */
  _validateParentElement() {
    const {element} = this;
    if (!this._el()) {
      throw new Error(`Element ${element} is not visible in current DOM node`)
    }
    Logger.info(`Element [${element}] is visible in current DOM node`)
  }

  /**
   * @return {HTMLElement}
   * @private
   */
  _el() {
    return document.getElementById(this.element)
  }

  /**
   * @param player
   * @return {HTMLElement}
   * @private
   */
  _createTag(player) {
    const parentEl = this._el();
    let tagName = (player === 'videojs' || player === 'mediaelement') ? 'video' : 'div';
    const tag = document.createElement(tagName)

    tag.setAttribute('data-matomo-title', this.id);
    tag.setAttribute('class', this._getPlayerClassName(player));
    tag.setAttribute('id', this.id);
    parentEl.appendChild(tag);

    return tag;
  }

  _getPlayerClassName(player) {
    const classes = (Config.get('className', '') +
      ' ' + Config.get(`playerOptions.${player}.defaultClassName`, ''))
      .trim();

    Logger.info(`Player classes [${classes}]`);

    return classes
  }

  /**
   * @private
   */
  _randomElement() {
    this.id = randomUUID();
    Logger.info(`Player instance will be inserted into [${this.id}] element`)
  }
}
