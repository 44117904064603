import mapper from '../mappers/mediaelement'
import Logger from '../../core/Logger';
import Http from '../../core/Http';
import EventBus from '../../core/EventBus';
import {bundleLoaded, embedBundleScripts, embedIMA, embedMatomo} from '../../helpers';
import Config from "../../core/Config";

/** @type {string}*/
const BUNDLE_CLASS_NAME = 'hibrid-vod-mediaelement-player';

let attempts = 0;

const validateLoaded = (callback) => setTimeout(() => {
    if (window.MediaElementPlayer) return callback();
    if (attempts === 10) throw new Error('An error occur while booting MediaElement player');
    attempts++;
    return validateLoaded(callback);
}, 100);
/**
 * @param player
 * @param options {{}}
 * @param entry {{}}
 * @param sources {Array}
 */
const onPlayerInit = (player, options, entry, sources) => {
    // activate playlist plugin if entry type is playlist
    // if (entry.type === 'playlist') {
    //     player.playlist(sources);
    //     player.playlist.autoadvance(0)
    //     player.playlistUi()
    // }

    //
    // if (player.error()) {
    //     console.log(player.error(), 'err')
    //     Logger.error('Player error');
    //     Logger.error(error)
    // }
};

export default {
    /**
     * @param player {string}
     * @param callback {function}
     */
    boot(player, callback) {
        embedIMA();
        // embedMatomo(Config.get('mtm', ''));
        // in case if bundle was already injected into DOM
        // we should not embed it again
        if (bundleLoaded(BUNDLE_CLASS_NAME)) {
            Logger.info(`${BUNDLE_CLASS_NAME} already exist in DOM`);
            return Promise.resolve();
        }
        Logger.info('Loading a bundle for videos player');
        // fetching fresh bundle from API and embedding scripts into DOM
        return Http.bundle(player)
            .then(({data}) => {
                embedBundleScripts(data, BUNDLE_CLASS_NAME) && validateLoaded(callback)
            })
            .catch((e) => console.log(e))
    },
    /**
     * @param entry {{}}
     * @param attributes {{}}
     * @param element {HTMLElement}
     * @param callback {function}
     */
    render(entry, attributes, element, callback) {
        const sources = mapper(entry);
        const [{poster}] = sources;
        const options = {
            ...attributes.options, poster,
            advertising: attributes.advertisement_tag
        };

        // Logger.info(`Rendering player with options ${JSON.stringify(options)}`);
        // // html attributes
        if (options.loop) element.loop = 'loop'
        if (options.muted) {
            element.muted = 'muted'
            element.onloadedmetadata = 'this.muted = true' // FIREFOX FIX
        }
        if (options.controls) element.controls = 'controls'
        if (options.autoplay) element.autoplay = 'autoplay'
        options.poster = poster
        //
        if (entry.type === 'video' || entry.type === 'stream') {
            //turn off playlist if type - video
            const playlistModules = ['playlist', 'prevtrack', 'nexttrack', 'shuffle', 'loop']
            playlistModules.map(value => options.features = options.features.filter(item => item !== value))
            options.features.push('header')
            element.src = sources[0].src
            element.type = sources[0].type

            options.headerTitle = sources[0].title;
            options.headerDescription = sources[0].description;
            options.headerVisible = true
        }

        if (entry.type === 'playlist') {
            element.src = sources[0].src
            element.type = sources[0].type
            options.playlist = sources;
            console.log(options.features)
        }
        element.width = options.width;
        element.height = options.height;
        // // new MediaElementPlayer(element, options)
        let player = new MediaElementPlayer(element, options)
        Logger.info(`MediaElement.js player rendered`);
        //
        element.addEventListener('play', function (e) {
            Logger.error('Player play');
            if (entry.type === 'stream') return
            EventBus.emit('player.play', player.getSrc())
        });

        // onPlayerInit(player, options, entry, sources);

    },
    remote(entry, attributes, element, callback) {
        const sources = mapper(entry);
        const [{poster}] = sources;
        const options = {
            ...attributes.options, poster,
            advertising: attributes.advertisement_tag
        };

        if (options.loop) element.loop = 'loop'
        if (options.muted) {
            element.muted = 'muted'
            element.onloadedmetadata = 'this.muted = true' // FIREFOX FIX
        }
        if (options.controls) element.controls = 'controls'
        if (options.autoplay) element.autoplay = 'autoplay'
        options.poster = poster
        //
        if (entry.type === 'video' || entry.type === 'stream') {
            //turn off playlist if type - video
            const playlistModules = ['playlist', 'prevtrack', 'nexttrack', 'shuffle', 'loop']
            playlistModules.map(value => options.features = options.features.filter(item => item !== value))
            options.features.push('header')
            element.src = sources[0].src
            element.type = sources[0].type

            options.headerTitle = sources[0].title;
            options.headerDescription = sources[0].description;
            options.headerVisible = true
        }

        element.width = options.width;
        element.height = options.height;
        let player = new MediaElementPlayer(element, options)
        Logger.info(`MediaElement.js player rendered`);
    }
}
