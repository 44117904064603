import Http from '../core/Http'
import Logger from '../core/Logger'

/**
 * @class Livestream
 */
export default class Livestream {

    /**
     * @param stream_id {string}
     */
    constructor(stream_id) {
        this.id = stream_id;
    }

    /**
     * @param data
     */
    setData(data) {
        this.data = data
    }

    /**
     * @returns {Promise<void>}
     */
    fetch() {
        Logger.info(`Fetching an livestream by id [${this.id}]`)
        return Http.livestream(this.id).then(({data}) => {
            this.setData(data) && Logger.info('Livestream request succeeded')
        })
            .catch((e) => {
                this.__handleError(e)
            })
    }

    /**
     * @param error
     * @private
     */
    __handleError(error) {
        throw new Error('An error occur while fetching livestream')
    }
}