import Logger from './core/Logger';
import {EMBED_BUNDLE_PATH} from './core/Http'

export function randomUUID() {
  let a = 'video-';
  const c = Date.now() / 1000;
  let d = c.toString(16).split('.').join('');
  while (d.length < 14) {
    d += '0';
  }
  let e = "";
  const f = Math.round(Math.random() * 100000000);
  e += f;
  return a + d + e;
}

/**
 * @param path {string}
 * @param player {string}
 * @return {string}
 */
export const embedBundleUrl = (path, player) => `${EMBED_BUNDLE_PATH}${player}/${path.trim()}`;

/**
 * @param bundle {{ player: string, scripts: {css: [], script: []} }}
 * @param className {string}
 */
export function embedBundleScripts({scripts, player}, className) {
  scripts.css.forEach((item) => embedCSS(item, player, className));
  scripts.script.forEach((item) => embedJS(item, player, className));

  return true;
}

/**
 * Embed single CSS style script
 * @param path {string}
 * @param player {string}
 * @param className {string}
 */
export function embedCSS(path, player, className) {
  const element = createTagElement({
    tag: 'link',
    url: embedBundleUrl(path, player),
    className
  });
  appendElement(element)
}

/**
 * Embed single JS script
 * @param path {string}
 * @param player {string}
 * @param className {string}
 * @param url {string}
 */
export function embedJS(path, player, className, url = undefined) {
  const element = createTagElement({
    tag: 'script',
    url: url || embedBundleUrl(path, player),
    className
  });
  appendElement(element)
}

/**
 * @param params {{ tag: string, className: string, url: string}}
 */
export function createTagElement(params) {
  const element = document.createElement(params.tag);

  if (params.tag === 'script') {
    element.setAttribute('type', 'text/javascript');
    element.setAttribute('src', params.url);
  } else {
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('href', params.url);
  }
  element.classList.add(params.className);
  element.onload = () => Logger.info(`Bundle ${params.url} successfully loaded to DOM`);
  element.onerror = () => {
    throw new Error(`An error occur while embedding ${params.url} file.`)
  };
  return element;
}

/**
 * @param className {string}
 * @return {boolean}
 */
export function bundleLoaded(className) {
  return document.getElementsByClassName(className).length > 0;
}

/** @param element {HTMLElement} */
const appendElement = (element) => {
  document
    .getElementsByTagName('head')
    .item(0)
    .appendChild(element)
};

/**
 * Embed google IMA sdk into DOM
 */
export function embedIMA() {
  const className = 'hibrid-vod-ima';
  if (bundleLoaded(className)) {
    return Logger.info('IMA SKD already loaded into DOM');
  }
  embedJS(undefined, undefined, className, '//imasdk.googleapis.com/js/sdkloader/ima3.js')
}

/**
 * mbed matomo sdk into DOM
 */
export function embedMatomo(siteId) {
  const className = 'hibrid-vod-matomo';
  const matomoUrl = 'https://hibridmena.matomo.cloud/';
  if (bundleLoaded(className)) {
    return Logger.info('Matomo SDK already loaded into DOM');
  }

  embedMatomoScript(matomoUrl, siteId)
  embedJS(undefined, undefined, className, matomoUrl + 'piwik.js')
}

/**
 * @param url
 * @param siteId
 */
export function embedMatomoScript(url, siteId) {
  const element = document.createElement('script');

  element.setAttribute('type', 'text/javascript');
  element.innerHTML = "var _paq = window._paq = window._paq || [];" +
                      "_paq.push(['trackPageView']);" +
                      "_paq.push(['enableLinkTracking']);" +
                      "_paq.push(['setTrackerUrl', '" + url + "matomo.php']);" +
                      "_paq.push(['setSiteId', " + siteId + "]);";
  appendElement(element)
}

/**
 * @param url
 * @param siteId
 */
export function embedHibridPlayerScript(version = `v3.4.3.4` , bundleClassName) {
  const element = document.createElement('script');

  element.setAttribute('type', 'text/javascript');
  element.setAttribute('src', `https://hiplayer.hibridcdn.net/l/${version}/hiplayerloader.min.js`);
  element.setAttribute('class', bundleClassName);

  appendElement(element)

  return true;
}
