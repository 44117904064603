import hiplayer from './loaders/hiplayer'
import videojs from './loaders/videojs'
import mediaelement from './loaders/mediaelement'

export default class Factory {
  /**
   * @type {{videojs: {boot(): void, render(*=, *, *=, *): void}}}
   */
  static players = {
    hiplayer: hiplayer,
    videojs: videojs,
    mediaelement: mediaelement
  };

  /**
   * @type {undefined|Player}
   */
  static player = undefined;

  /**
   * @param player {string}
   * @param callback {function}
   */
  make(player, callback) {
    if (!player in Object.keys(Factory.players)) {
      throw new Error(`Player [${player}] is not supported in current build`)
    }
    Factory.player = Factory.players[player];
    Factory.player.boot(player, callback)
  }

  /**
   * @param entry
   * @param attributes
   * @param element
   * @param callback
   */
  render(entry, attributes, element, callback) {
    Factory.player.render(entry, attributes, element, callback)
  }
  //Play remote video
  remote(source, attributes, element, callback) {
    Factory.player.remote(source, attributes, element, callback)
  }
}
