import Config from "./Config";
import Logger from "./Logger";

export default class ScreenGenerator {
    constructor(message) {
        this.message = message;
        this.element = Config.get('el');
        this._validateParentElement();
    }

    /**
     * This method is verified if user is not cheating
     * and parent element exist in current DOM node
     *
     * In case if element is not exist, we simple must throw an exception
     * and don't send any xhr request to the server
     *
     * @private
     */
    _validateParentElement() {
        const {element} = this;
        if (!this._el()) {
            throw new Error(`Element ${element} is not visible in current DOM node`)
        }
        Logger.info(`Element [${element}] is visible in current DOM node`)
    }

    _el() {
        return document.getElementById(this.element)
    }

    renderWarningPage() {
        const parentEl = this._el()
        const wrapper = document.createElement('div')
        const logoWrapper = document.createElement('span')
        const textWrapper = document.createElement('span')
        logoWrapper.setAttribute('style', 'font-size: 37px; width: 47px;height: 47px;display: inline-block;border: 3px solid rgba(255,255,255,0.8);border-radius: 100%; color: rgba(255,255,255,0.8);vertical-align: middle;')
        textWrapper.setAttribute('style', 'vertical-align: middle;display: inline-block;margin:10px 20px;font-size:17px;color: rgba(255,255,255,0.8);')
        const text = document.createTextNode('!'); // create a new text node with the content
        const errorText = document.createTextNode(this.message); // create a new text node with the content
        logoWrapper.appendChild(text);
        textWrapper.appendChild(errorText);
        wrapper.appendChild(logoWrapper)
        wrapper.appendChild(textWrapper)
        parentEl.appendChild(wrapper);
        parentEl.setAttribute('style', 'text-align: center;background-color:black;  display: flex;align-items: center;justify-content: center;height: 100%')
        wrapper.setAttribute('style', '')
    }
}
