export default (entry) => {

    if (entry.type === 'video') {
        return [{
            id: entry.data.id,
            src: entry.data.embed_url,
            type: 'application/x-mpegURL',
            poster: entry.data.thumbnail
        }]
    }

    if (entry.type === 'stream') {
        return [{
            id: entry.data.id,
            src: entry.data.url,
            type: 'application/x-mpegURL',
            poster: entry.data.poster
        }]
    }

    return entry.data.videos.map((video) => {
        return {
            sources: [{
                src: video.embed_url,
                type: 'application/x-mpegURL'
            }],
            name: encodeURI(video.title),
            poster: video.thumbnail,
            duration: video.duration
        }
    })
}
