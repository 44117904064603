import mapper from '../mappers/hiplayer'
import Logger from '../../core/Logger';
import Http from '../../core/Http';
import EventBus from '../../core/EventBus';
import {bundleLoaded, embedHibridPlayerScript, embedIMA, embedMatomo} from '../../helpers';
import Config from "../../core/Config";

/** @type {string}*/
const BUNDLE_CLASS_NAME = 'hibrid-vod-hibrid-player';

let attempts = 0;

const validateLoaded = (callback) => setTimeout(() => {
    if (window.HiplayerLoader) return callback();
    if (attempts === 10) throw new Error('An error occur while booting Hibrid player');
    attempts++;
    return validateLoaded(callback);
}, 100);
/**
 * @param player
 * @param options {{}}
 * @param entry {{}}
 * @param sources {Array}
 */
const onPlayerInit = (element, options, entry, sources) => {


    // player.on('play', () => {
    //     EventBus.emit('player.play', player.currentSource())
    // });
    //
    // player.on('error', (e) => {
    //     Logger.error('Player error');
    //     Logger.error(e)
    // });
};

export default {
    /**
     * @param player {string}
     * @param callback {function}
     */
    boot(player, callback) {
        embedIMA();
        // embedMatomo(Config.get('mtm', ''));
        // in case if bundle was already injected into DOM
        // we should not embed it again
        if (bundleLoaded(BUNDLE_CLASS_NAME)) {
            Logger.info(`${BUNDLE_CLASS_NAME} already exist in DOM`);
            return Promise.resolve();
        }
        Logger.info('Loading a bundle for hibrid player');
        // fetching fresh bundle from API and embedding scripts into DOM
        // return Http.bundle(player)
        //     .then(({data}) => {
        //     })
        //     .catch((e) => console.log(e))

        //Don't load bundle for HiPlayer, because we haven't bundle in embed  api
        embedHibridPlayerScript('v8.1.1', BUNDLE_CLASS_NAME) && validateLoaded(callback)
    },
    /**
     * @param entry {{}}
     * @param attributes {{}}
     * @param element {HTMLElement}
     * @param callback {function}
     */
    render(entry, attributes, element, callback) {
        const sources = mapper(entry);
        const [{poster}] = sources;
        const options = {
            ...attributes.options, poster
        };
        options.hbAssetKey = entry.entry_id
        options.gaAssetKey = entry.entry_id

        if (entry.data && entry.data.subtitles) {
            let tracks = [];
            try{
                entry.data.subtitles.forEach(item => {
                    let obj = {}
                    obj.lang = item.language
                    obj.label = item.title
                    obj.url = process.env.APP_EMBED_URL + '/subtitles?url=' + item.url
                    obj.default = item.language === entry.options.lang
                    tracks.push(obj)
                });
                if (tracks.length) {
                    options.textTrackEnabled =  true
                    options.textTracks = tracks
                }

            }catch (e){
                console.log(e.toString())
            }
        }

        if (entry.type === 'video' || entry.type === 'stream') {
            options.streamUrl =  sources[0].src;
            options.playlistEnabled = false;
        }else if (entry.type === 'playlist') {
            options.streamUrl = null;
            options.playlistEnabled = true;
            options.playlistUrls = sources;
        }
        for (let key in options) {
            if (options[key] === null) {
                options[key] = ``
            }
        }

        if(options.gaTrackingEnabled)
            options.gaTrackingId = attributes.ga_view_id

        Logger.info(`Rendering player with options ${JSON.stringify(options)}`);

        HiplayerLoader.setup(
            element.id, window.btoa(JSON.stringify(options))
        )

        Logger.info(`Hibrid player rendered`);

        onPlayerInit(element, options, entry, sources);
    },

    remote(entry, attributes, element, callback) {
        const sources = mapper(entry);
        const [{poster}] = sources;
        const options = {
            ...attributes.options, poster
        };
        options.hbAssetKey = entry.entry_id
        options.gaAssetKey = entry.entry_id


        if (entry.type === 'video' || entry.type === 'stream') {
            options.streamUrl =  sources[0].src;
            options.playlistEnabled = false;
        }
        for (let key in options) {
            if (options[key] === null) {
                options[key] = ``
            }
        }

        if(options.gaTrackingEnabled)
            options.gaTrackingId = attributes.ga_view_id

        Logger.info(`Rendering player with options ${JSON.stringify(options)}`);

        HiplayerLoader.setup(
            element.id, window.btoa(JSON.stringify(options))
        )

        Logger.info(`Hibrid player rendered`);
        console.log(options)
        onPlayerInit(element, options, entry, sources);
    }
}
