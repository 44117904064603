import {get} from 'lodash'

export default class Config {
  static __config = [];

  /** @param config {object}*/
  static init(config) {
    Object
      .keys(config)
      .forEach((key) => this.set(key, config[key]))
    // additional params
    Config.set('correlation-id', Math.random())
  }

  /**
   * @param key {string}
   * @param value {*}
   */
  static set(key, value) {
    Config.__config[key] = value
  }

  /**
   * @param key {string}
   * @param replace
   * @return {*}
   */
  static get(key, replace) {
    return get(Config.__config, key, replace)
  }
}
